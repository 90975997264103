import { SERVICE_BY_SKILL } from '@/constants/routes'
import GigListItem from '@/models-ts/gigs/GigListItem'
import { RootState } from '@/store'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import GigCard from '@/partials/GigCard/GigCard.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    GigCard,
  },
  data () {
    return {
      swipers: [],
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      isLoaded: (state: RootState) => state.browseGigs.details?.isLoaded,
    }),
    ...mapGetters({
      gigDetails: 'browseGigs/gigDetails',
    }),
    freelancer () {
      return this.gigDetails.relations.Freelancer
    },
    userGigs () {
      return (this.gigDetails.more?.gigs.by_user || []).map(GigListItem.fromServer)
    },
    categoryGigs () {
      return (this.gigDetails.more?.gigs.by_category || []).map(GigListItem.fromServer)
    },
    category () {
      return this.gigDetails.meta.category || {}
    },
    moreLink () {
      return { name: SERVICE_BY_SKILL, params: { skill: this.category?.url } }
    },
  },
  watch: {
    isLoaded: {
      handler () {
        if (this.isLoaded) {
          this.$nextTick(async () => {
            // @ts-ignore
            const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default;
            ['userGigs', 'otherGigs'].forEach(ref => {
              if (this.$refs[ref]) {
                const swiper = new Swiper(this.$refs[ref], {
                  slidesPerView: 'auto',
                  spaceBetween: 32,
                  freeMode: true,
                  navigation: {
                    nextEl: this.$refs[`${ref}NextBtn`].$el,
                    prevEl: this.$refs[`${ref}PrevBtn`].$el,
                  },
                })
                swiper.allowTouchMove = false
                this.swipers.push(swiper)
              }
            })
          })
        }
      },
      immediate: true,
    },
  },
})
