import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { CURRENCIES } from '@/constants/currency'
import { Blockchain } from '@/constants/blockchain'
import { SERVICE_BY_SKILL } from '@/constants/routes'
import { formatDescription } from '@/utils-ts/strings'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Image from '@/models/Image'
import { getHumanDate } from '@/utils/date'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      mobileMoreSkillsOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      gigDetails: 'browseGigs/gigDetails',
    }),
    category () {
      return this.gigDetails.meta.category || {}
    },
    skills () {
      const categorySkill = this.category
        ? {
          text: `Category: ${this.category.name}`,
          link: {
            name: SERVICE_BY_SKILL,
            params: { skill: this.category.url },
          }
        }
        : null
      const skills = this.gigDetails.relations.Skill
        .filter((skill: any) => skill.id !== this.category.id)
        .map((skill: any) => ({
          text: skill.name,
          ...(!skill.is_custom && skill.url && { link: { name: SERVICE_BY_SKILL, params: { skill: skill.url } } })
        }))
      if (categorySkill) {
        skills.unshift(categorySkill)
      }
      return skills
    },
    description () {
      return formatDescription(this.gigDetails.description)
    },
    files () {
      return [
        ...this.gigDetails.images.map((image: any, i: number) => new Image({
          ...image,
          alt: `${this.name} image ${i + 1}`
        })),
        ...this.gigDetails.relations.File
      ]
      //   .map((file: any) => {
      //   const chunks = file.description.split('.')
      //   let name, ext
      //   if (chunks.length > 1) {
      //     name = chunks.slice(0, -1).join('.')
      //     ext = `.${chunks[chunks.length - 1]}`
      //   } else {
      //     name = chunks[0]
      //   }
      //   return { name, ext, icon: getIcon(file), src: file.src }
      // })
    },
  },
  methods: {
    getHumanDate,
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onClickImage (index: number) {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
          props: {
            images: [
              {
                id: this.gigDetails.banner.id,
                description: this.gigDetails.banner.description,
                src: this.gigDetails.banner.src || this.gigDetails.banner.base64,
              },
              ...this.gigDetails.images.map((image: any) => ({
                id: image.id,
                description: image.description,
                src: image.src || image.base64,
              }))
            ],
            initialImage: index,
          },
        }
      })
    },
    onClickDescriptionLink (e: any) {
      const link = e.target
      if (link && (link.nodeName === 'A' || link.nodeName === 'a')) {
        googleAnalyticsV2.send({
          event: 'gig-click-on-external-link',
          name: link.href,
          gig_id: this.gigDetails.id,
        })
      }
    },
    onClickSkill (skill: any) {
      googleAnalyticsV2.send({
        event: 'gig-click-on-skill',
        name: skill.text,
        gig_id: this.gigDetails.id,
      })
    },
  },
})
