var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasBtns)?_c('div',{staticClass:"root mobile-actions-card"},[_c('no-ssr',[(_vm.hasBookmarkBtn)?_c('BookmarkIconButton',{staticClass:"icon-bookmark-btn",attrs:{"id":_vm.gigDetails.id,"type":_vm.bookmarkType}}):_vm._e(),(_vm.hasPostBtn)?_c('lx-button-private',{class:{
        'lx-blue-btn': !_vm.canHire,
        'big': !_vm.canHire,
        'lx-empty-btn': _vm.canHire,
        'mobile-post-btn': _vm.canHire,
      },on:{"click":_vm.onClickPost}},[_vm._v("Create a Gig like this")]):_vm._e(),(_vm.hasHireBtn)?_c('lx-button-private',{class:{
        'lx-blue-btn': true,
        'mobile-buy-btn': true,
        big: !_vm.hasPostBtn,
      },on:{"click":_vm.onClickHire}},[_vm._v("Buy this Gig")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }