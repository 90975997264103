import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import get from 'lodash/get'
import { mapState } from 'vuex'
import Button from '@/models-ts/ui/Button'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import {
  FREELANCER_PROFILE,
  SERVICE_EDIT,
  SERVICE_MY
} from '@/constants/routes'
import { STATUS_PUBLISHED, TIME_HOURLY } from '@/constants/backend/service'
import { deleteService, publishService, unpublishService } from '@/api/gig'
import { formatUsd } from '@/utils/moneyFormat'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { getCurrency } from '@/utils-ts/currencies'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'
import BookmarkButton from '@/partials/BookmarkButtons/BookmarkButton/BookmarkButton.vue'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { RootState } from '@/store'
import responseMixin from '@/mixins/responseMixin'
import zendeskMixin from '@/mixins/zendeskMixin'
import actionsCardMixin from '../actionsCardMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, responseMixin, zendeskMixin],
  components: { NoSsr, BookmarkButton, BookmarkIconButton },
  data () {
    return {
      publishing: false,
      published: false,
      deleting: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoading: (state: RootState) => state.browseGigs.details?.isLoading,
    }),
    failedModeration () {
      return this.gigDetails.moderationStage === ModerationStages.FAILED
    },
    viewsCount () {
      if (this.gigDetails.views) {
        return this.gigDetails.views > 1000 ? '1000+' : this.gigDetails.views
      }
    },
    purchasesCount () {
      if (this.gigDetails.meta?.completedContractsCount) {
        return this.gigDetails.meta.completedContractsCount > 1000 ? '1000+' : this.gigDetails.meta.completedContractsCount
      }
    },
    name () {
      return this.gigDetails?.name || ''
    },
    freelancer () {
      const freelancer = this.gigDetails.relations.Freelancer
      return {
        ...freelancer,
        link: { name: FREELANCER_PROFILE, params: { id: freelancer.id } },
      }
    },
    isHourly () {
      return this.gigDetails.time_type === TIME_HOURLY
    },
    rate () {
      const cost = `${formatUsd(this.gigDetails.rate)}`
      return this.isHourly ? `${cost}/h` : cost
    },
    currenciesLabel () {
      const mapped = (this.gigDetails?.relations?.Currency || []).map((c: any) => getCurrency({
        blockchain: c.blockchain,
        value: c.currency,
        field: CURRENCY_FIELD_BACKEND_ID,
      })).filter(Boolean).map((c: any) => c.name)
      return mapped.slice(0, 3).join(', ')
    },
    currencyMore () {
      return this.gigDetails?.relations?.Currency?.length > 3
        ? ` +${this.gigDetails.relations.Currency.length - 3}`
        : null
    },
    editLink () {
      return { name: SERVICE_EDIT, params: { id: this.gigDetails.id } }
    },
    canPublish () {
      return this.isOwner && this.isFreelancer && this.gigDetails.moderationStage !== ModerationStages.FAILED
    },
    hasEditBtn () {
      return this.isOwner && this.isFreelancer && !this.failedModeration
    },
    hasShareBtn () {
      return this.isOwner &&
        this.isFreelancer &&
        this.gigDetails.moderationStage === ModerationStages.PASSED &&
        this.gigDetails.status === STATUS_PUBLISHED
    },
    hasSupportBtn () {
      return this.failedModeration
    },
    hasUnpublishBtn () {
      return this.canPublish && this.published
    },
    hasPublishBtn () {
      return this.canPublish && !this.published
    },
    hasDeleteBtn () {
      return this.isOwner
    },
    hasMenu () {
      return this.hasShareBtn || this.hasSupportBtn || this.hasUnpublishBtn || this.hasDeleteBtn
    },
    hasBtns () {
      return this.hasPublishBtn || this.hasHireBtn || this.hasPostBtn || this.hasBookmarkBtn
    },
  },
  created () {
    this.published = this.gigDetails.status === STATUS_PUBLISHED
  },
  methods: {
    onSocialItemClick (name: string) {
      googleAnalyticsV2.send({
        event: 'gig-click-on-sharing-button',
        name,
        gig_id: this.gigDetails.id,
      })
    },
    onClickShare () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "share-modal" */ '@/modals/ShareModal/ShareModal.vue'),
          title: 'Share this Gig',
          props: {
            type: 'gig',
            id: this.gigDetails.id,
            name: this.gigDetails.name,
            slug: this.gigDetails.slug,
          },
        }
      })
    },
    // async onSendMessageClick () {
    //   const room = this.rooms.find((room: ChatRoom) =>
    //     room.entity.id === this.gigDetails.id &&
    //     !room.isClosed &&
    //     room.customerStage !== RoomStages.ARCHIVED
    //   )
    //   if (room) {
    //     this.$router.push(room.chatLink)
    //   } else {
    //     let room = new ChatRoom({
    //       id: uniqueId('tmp_room_'),
    //       type: RoomTypes.GIG,
    //       status: RoomStatuses.OPEN,
    //       participants: [
    //         new ChatParticipant({
    //           id: this.userId,
    //           avatar: this.avatar,
    //           name: this.$store.getters['user/name'],
    //           isBanned: false,
    //           isRemoved: false,
    //           isOnline: false,
    //         }),
    //         new ChatParticipant({
    //           id: this.freelancer.id,
    //           avatar: this.freelancer.avatar,
    //           name: this.freelancer.name,
    //           isBanned: false,
    //           isRemoved: false,
    //           isOnline: false,
    //         }),
    //       ],
    //       entity: {
    //         id: this.gigDetails.id,
    //         slug: this.gigDetails.slug,
    //         name: this.gigDetails.name,
    //       },
    //       temporary: true,
    //       customerStage: RoomStages.NEW,
    //     })
    //     room = await this.createRoom(room)
    //     this.$router.push(room.chatLink)
    //   }
    // },
    async onClickPublish () {
      try {
        this.publishing = true
        await notifiableRequest({
          request: async () => publishService(this.gigDetails.id),
          successText: ({ moderation_stage }) => moderation_stage === ModerationStages.PASSED
            ? `Gig: <b>${this.gigDetails.name}</b> was successfully published`
            : 'Your Gig was successfully submitted for moderation. We will notify you of the outcome shortly.',
          failureText: (e: any) => {
            const defaultErr = 'Gig publishing error'
            return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
          }
        })
        googleAnalyticsV2.send({
          event: 'gig-published',
          gig_id: this.gigDetails.id,
        })
        this.published = true
      } catch (e) {
        this.parseError(e)
      } finally {
        this.publishing = false
      }
    },
    async onClickUnpublish () {
      try {
        this.publishing = true
        await notifiableRequest({
          // @ts-ignore
          request: async () => unpublishService(this.gigDetails.id),
          title: 'Gig unpublished',
          successText: `Gig: <b>${this.name}</b> has been unpublished.`,
          failureText: 'Error unpublishing Gig. Please try again.',
        })
        this.published = false
      } catch (e) {
        this.parseError(e)
      } finally {
        this.publishing = false
      }
    },
    onClickDelete () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Delete Gig',
          text: `Are you sure you want to delete <i>\`${this.name}\`</i>? This Gig will be permanently deleted.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
              onClick: () => {},
            }),
            new Button({
              text: 'Delete',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    // @ts-ignore
                    request: async () => {
                      await deleteService(this.gigDetails.id)
                      this.$router.push({ name: SERVICE_MY })
                    },
                    title: 'Gig deleted',
                    successText: `Gig: <b>${this.name}</b> has been deleted`,
                    failureText: 'Error deleting Gig. Please try again.',
                  })
                  this.$emit('delete')
                } catch (e) {
                  this.parseError(e)
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        },
      })
    },
  },
})
