import { ModerationStages } from '@/constants/backend/ModerationStages'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import Vue, { PropType } from 'vue'
import GigStatusBadge from '@/partials/StatusBadges/GigStatusBadge/GigStatusBadge.vue'

export default Vue.extend({
  components: {
    GigStatusBadge,
  },
  props: {
    status: Number as PropType<GigStatuses>,
    moderationStage: Number as PropType<ModerationStages>,
  },
})
