import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import omit from 'lodash/omit'
import striptags from 'striptags'
import unescape from 'lodash/unescape'
import uniqueId from 'lodash/uniqueId'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import Gig from '@/models/backend/Gig'
import animateScrollTo from '@/utils/animateScrollTo'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import * as buttons from '@/constants/components/button'
import GigCard from '@/partials/GigCard/GigCard.vue'
import GigShortPreview from '@/models-ts/gigs/GigShortPreview'
import { Blockchain, EVM_BLOCKCHAIN, WalletGroup } from '@/constants/blockchain'
import { CURRENCIES } from '@/constants/currency'
import { DASHBOARD, LANDING, SERVICE_EDIT, SERVICE_ADD, SERVICE_DETAILS, SERVICE_MY } from '@/constants/routes'
import { TIME_FIXED, TIME_HOURLY } from '@/constants/backend/service'
import { Roles } from '@/constants/user/roles'
import { createService, editService, getService, publishService } from '@/api/gig'
import snackMixin from '@/mixins/snackMixin'
import { isImage } from '@/utils/file'
import { parseSlug } from '@/utils/parser'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import skillableMixin from '@/mixins/skillableMixin'
import { RootState } from '@/store'
import Skill from '@/models-ts/Skill'
import Currency from '@/models-ts/Currency'
import Wallet from '@/models-ts/Wallet'
import { ModerationStages } from '@/constants/backend/ModerationStages'

const FILE_MB_LIMIT = 5
const FILE_LIMIT = FILE_MB_LIMIT * 1024 * 1024
const MAX_IMAGES = 5
const MAX_FILES = 10
const MAX_SKILLS = 10

interface GigCurrency {
  blockchain: Blockchain
  currency: Currency['backendId']
}

interface GAEvent {
  event: string
  name?: string
  gig_id?: number
  'event-content'?: string
}

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin, skillableMixin],
  components: {
    PrefferedCurrency,
    GigCard,
  },
  data () {
    return {
      DASHBOARD,
      LANDING,
      TIME_FIXED,
      TIME_HOURLY,
      SERVICE_MY,
      processing: false,
      processingSaved: false,
      processingSavedAndPublish: false,
      preloading: false,
      readOnly: false,
      rate: 0,
      // category: null,
      service: new Gig(),
      initService: new Gig(),
      buttons,
      preferredCurrencies: [],
      numericInputReady: false,
      hasTronWallets: false,
      hasEvmWallets: true,
      bannerType: null,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      userIsLoaded: (state: RootState) => state.user.profile.isLoaded,
      userProfile: (state: RootState) => state.user.profile.value,
      loadableProfile: (state: RootState) => state.user.profile,
      wallets: (state: RootState) => state.user.wallets,
      avatar: (state: RootState) => state.user.avatar,
      userId: (state: RootState) => state.user?.id,
      userType: (state: RootState) => state.user?.type,
    }),
    ...mapGetters({
      // backendConfig: 'user/getConfigMap',
      getSkillChilds: 'skills/getChilds',
      getSkillRoots: 'skills/getRoots',
      userName: 'user/name',
    }),
    isEdit () {
      return this.$route.name === SERVICE_EDIT
    },
    pageTitle () {
      return this.isEdit ? 'Edit Gig' : 'Create New Gig'
    },
    gigShort () {
      return new GigShortPreview({
        id: this.service.id,
        slug: this.service.slug,
        name: this.service.name,
        banner: this.banner,
        skills: this.service.relations.Skill,
        rate: this.service.rate,
        timeType: this.service.time_type,
        timeValue: this.service.time_value,
        userId: this.userId,
        userType: this.userType,
        userName: this.userProfile?.name,
        avatar: this.avatar,
        avgReview: this.userProfile?.avgReview,
        userReviews: this.userProfile?.userReviews,
        views: this.service.views,
      })
    },
    isUpdated () {
      const compare = (): boolean => {
        const service = omit(this.service, ['relations.Currency', 'rate', 'time_value'])
        const initService = omit(this.initService, ['relations.Currency', 'rate', 'time_value'])
        const timeEqual = !this.isHourlyBased || Number(this.service.time_value) === Number(this.initService.time_value)
        const rateEqual = Number(this.service.rate) === Number(this.initService.rate)
        return rateEqual && timeEqual &&
          isEqual(service, initService) &&
          isEqual(this.toServerCurrencies(), this.initService.relations.Currency)
      }
      return !isEqualWith(this.service, this.initService, compare)
    },
    isHourlyBased () {
      return this.service.time_type === TIME_HOURLY
    },
    banner () {
      const banners = this.service.relations.Banner
      if (banners) {
        return banners[0]?.base64 ? { src: banners[0].base64 } : this.service.banner
      }
    },
    files () {
      return [...this.service.images, ...this.service.relations.File]
    },
    skills () {
      return this.service.relations.Skill.map((skill: Skill) => this.predefinedSkills.find((s: Skill) => s.id === skill.id))
    },
    category: {
      get () {
        const skills = this.skills as Skill[]
        let skill
        if (skills?.length && this.skillsLoaded) {
          skill = skills.filter((skill: Skill) => !skill?.relations?.Parent.length)[0]
          if (!skill) {
            const roots = skills.map((s: Skill) => this.getSkillRoots(s)).reduce((acc: Skill[], roots: Skill[]) => acc.concat(roots))
            const commonRoot = Object.entries(groupBy(roots, 'id'))
              .reduce((commonRoot, root) => root[1].length > commonRoot[1].length ? root : commonRoot)
            skill = commonRoot[1][0]
          }
        }
        return skill
      }
    },
    categoryOptions () {
      return this.predefinedSkills.filter((skill: Skill) => !skill?.relations?.Parent.length)
    },
    skillOptions () {
      return this.category ? this.getSkillChilds(this.category.id) : []
    },
    skillTags () {
      return this.service.relations.Skill
        .filter((skill: Skill) => skill.id !== this.category?.id)
        .map((s: Skill) => ({ text: s.name }))
    },
    acceptedCurrenciesLabel () {
      if (this.preferredCurrencies.length > 0) {
        const curnames = this.preferredCurrencies.map((c: Currency) => c.name)
        const joined = curnames.slice(0, 3).join(', ')
        const postfix = curnames.length - 3 > 0 ? ` +${curnames.length - 3}...` : ''
        return `You accept payment in ${joined}${postfix}`
      }
      return null
    }
  },
  watch: {
    '$route.name': {
      async handler () {
        try {
          if (this.isLoggedIn) {
            await this.loadableProfile.awaitModelLoad()
            this.hasTronWallets = (this.wallets || [])
              .some((wallet: Wallet) => wallet.group === WalletGroup.TronLink)
            this.hasEvmWallets = (this.wallets || [])
              .some((wallet: Wallet) => [WalletGroup.Cloud, WalletGroup.Metamask, WalletGroup.WalletConnect].includes(wallet.group))
          } else {
            this.hasTronWallets = false
            this.hasEvmWallets = true
          }
          if (this.$route.name === SERVICE_ADD) {
            this.service = new Gig()
            this.initService = new Gig()
            CURRENCIES.forEach(curr => {
              if (curr.blockchain === Blockchain.Tron && this.hasTronWallets) {
                this.preferredCurrencies.push(curr)
              }
              if (
                EVM_BLOCKCHAIN.includes(curr.blockchain) && this.hasEvmWallets
              ) {
                this.preferredCurrencies.push(curr)
              }
            })
            this.initService.relations.Currency = this.preferredCurrencies
            const editor = document.querySelector('.ck-editor__editable')
            editor && (editor as any).ckeditorInstance.setData('')
          } else if (this.isEdit) {
            this.preloading = true
            this.initService = Gig.fromServer(await getService(this.$route.params.id))
            this.service = cloneDeep(this.initService)
            const filteredCurrencies = this.service.relations.Currency
              .map((c: GigCurrency) => CURRENCIES.find(cur => cur.backendId === c.currency && cur.blockchain === c.blockchain))
              .filter(Boolean)
            this.preferredCurrencies = cloneDeep(filteredCurrencies)
            this.preloading = false
          }
        } catch (e) {
          if (ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e)) {
            this.setNotFound(true)
          } else {
            this.parseError(e)
          }
        }
      },
      immediate: true
    },

  },
  async created () {
    try {
      let event = 'add-gig-from-profile'
      if (this.$route.query.createGigFrom === 'gig') {
        event = 'add-gig-from-another-gig'
      }
      googleAnalyticsV2.send({ event })
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    updateDescription (v: string) {
      this.service.description = v
    },
    async validateAll () {
      this.errors.clear()
      const hasBanner = !!this.service.relations.Banner?.length
      if (!hasBanner) {
        this.errors.add({
          field: 'cover',
          msg: 'The cover field is required'
        })
      }
      const skillsIsValid = this.service.relations.Skill?.length <= MAX_SKILLS + 1 // with category
      if (!skillsIsValid) {
        this.errors.add({
          field: 'skills',
          msg: `Maximum number of skills: ${MAX_SKILLS}`
        })
      }
      const hasCurrency = this.preferredCurrencies.length > 0
      if (!hasCurrency) {
        this.errors.add({
          field: 'currency',
          msg: 'The currencies is required'
        })
      }
      // const hasHours = !this.isHourlyBased || Number(this.service.time_value) > 0
      // if (!hasHours) {
      //   this.errors.add({
      //     field: 'hours',
      //     msg: 'The hours field is required'
      //   })
      // }
      const isValid = await this.$validator.validateAll()
      let descriptionLengthIsValid = true
      const description = unescape(striptags(this.service.description))
      const DESC_MIN = 100
      const DESC_MAX = 3000
      if (description) {
        if (description.length < DESC_MIN) {
          descriptionLengthIsValid = false
          this.errors.add({
            field: 'description',
            msg: `The description field must be at least ${DESC_MIN} characters`
          })
        }
        if (description.length > DESC_MAX) {
          descriptionLengthIsValid = false
          this.errors.add({
            field: 'description',
            msg: `The description field may not be greater than ${DESC_MAX} characters`
          })
        }
      }
      let isValidBudget = true
      let rate = this.service.rate
      if (this.isHourlyBased) {
        rate = this.service.rate * this.service.time_value
      }
      if (rate < 15) {
        this.errors.add({
          field: 'price',
          msg: 'The budget must be more than $14.99'
        })
        isValidBudget = false
      } else if (rate > 15000) {
        this.errors.add({
          field: 'price',
          msg: 'The budget must be less than $15,000'
        })
        isValidBudget = false
      }
      let isValidHours = true
      if (this.isHourlyBased && this.service.time_value < 1) {
        this.errors.add({
          field: 'hours',
          msg: 'The hours must be more than 1'
        })
        isValidHours = false
      }
      return hasBanner && skillsIsValid && hasCurrency && descriptionLengthIsValid && isValidBudget && isValidHours && isValid
    },
    onBlurName () {
      if (this.service.name) {
        this.service.name = this.service.name.trim()
      }
    },
    onSelectBanner () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/GigBannerUpload/GigBannerUpload.vue'),
          title: 'Select Gig Cover',
          props: {
            onSuccess: (file: UploadedFile, type: string) => {
              this.bannerType = type
              const buffer = file.base64.substring(file.base64.indexOf(',') + 1)
              if (buffer.length * 0.75 > FILE_LIMIT) {
                this.openSnackbar({
                  type: this.SnackTypes.FAILURE,
                  text: `File size cannot exceed ${FILE_MB_LIMIT} MB`,
                })
              } else {
                this.service.relations.Banner = [file]
                const params: GAEvent = { event: 'cover-upload' }
                if (this.isEdit) {
                  params.gig_id = this.service.id
                }
                googleAnalyticsV2.send(params)
              }
            }
          }
        }
      })
    },
    onClickDeleteBanner () {
      this.service.relations.Banner = []
    },
    onCategoryInput (skill: Skill) {
      if (skill) {
        this.service.relations.Skill = [skill]
        const params: GAEvent = {
          event: 'category-selected',
          name: skill.name,
        }
        if (this.isEdit) {
          params.gig_id = this.service.id
        }
        googleAnalyticsV2.send(params)
      } else {
        this.service.relations.Skill = []
      }
    },
    onSkillInput (skill: Skill) {
      if (!this.service.relations.Skill.find((s: Skill) => s.name === skill.name)) {
        this.service.relations.Skill.push(skill)
        const params: GAEvent = {
          event: 'skill-selected',
          name: skill.name,
        }
        if (this.isEdit) {
          params.gig_id = this.service.id
        }
        googleAnalyticsV2.send(params)
      }
    },
    onSkillDelete ({ tag }: { tag: CloudyTag }) {
      if (!this.processing) {
        const index = this.service.relations.Skill.findIndex((s: Skill) => s.name === tag.text)
        if (index !== -1) {
          this.service.relations.Skill.splice(index, 1)
        }
      }
    },
    onUploadFile (file: UploadedFile) {
      if (isImage(file)) {
        if (this.service.relations.Image.length < MAX_IMAGES) {
          this.service.relations.Image.push(file)
          const params: GAEvent = {
            event: 'images-upload',
          }
          if (this.isEdit) {
            params.gig_id = this.service.id
          }
          googleAnalyticsV2.send(params)
        }
      } else {
        if (this.service.relations.File.length < MAX_IMAGES) {
          this.service.relations.File.push(file)
        }
      }
    },
    onRemoveFile (file: any) {
      const fileIsImage = isImage(file)
      if (fileIsImage) {
        let files: any[] = this.service.relations.Image
        if (file.base64) {
          const index = files.findIndex(fl => fl === file)
          if (index !== -1) {
            files.splice(index, 1)
          }
        } else {
          this.service.relations.Image = files.filter(file => !(file.name && file.name.includes(file.name)))
        }
      } else {
        let files: any[] = this.service.relations.File
        let index = -1
        if (file.base64) {
          index = files.findIndex(fl => fl === file)
        } else {
          index = files.findIndex(fl => fl.name === file.name)
        }
        if (index !== -1) {
          files.splice(index, 1)
        }
      }
    },
    checkUploadingFile (file: UploadedFile) {
      const fileIsImage = isImage(file)
      const limited = fileIsImage
        ? this.service.images.length > MAX_IMAGES - 1
        : this.service.relations.File.length > MAX_FILES - 1
      if (limited) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `The maximum number of files has been reached ${fileIsImage ? MAX_IMAGES : MAX_FILES}`,
        })
        return false
      }
      return true
    },
    async onClickSave (withPublish = false) {
      try {
        this.errors.clear()
        if (await this.validateAll()) {
          this.processing = true
          if (withPublish) {
            this.processingSavedAndPublish = true
          } else {
            this.processingSaved = true
          }
          if (this.isEdit) {
            const { id, slug } = await notifiableRequest({
              request: async () => editService(this.service.id, this.getServerFormatGig()),
              title: 'Gig edited',
              successText: `Gig successfully updated.`,
              failureText: 'Error updating Gig. Please try again.',
            })
            this.$router.push({ name: SERVICE_DETAILS, params: { id, slug: parseSlug(slug) } })
          } else {
            const { id, slug } = await notifiableRequest({
              request: async () => createService(this.getServerFormatGig()),
              title: 'Gig created',
              successText: `Gig successfully created.`,
              failureText: (e) => {
                const defaultErr = 'Gig saving error'
                return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
              }
            })
            if (withPublish) {
              const snackbarId = uniqueId()
              setTimeout(async () => {
                this.openSnackbar({
                  id: snackbarId,
                  type: this.SnackTypes.LOADING,
                  text: `Gig: <b>${this.service.name}</b> is being published.`
                })
              }, 500)
              try {
                const { moderation_stage } = await notifiableRequest({
                  request: async () => publishService(id),
                  successText: ({ moderation_stage }) => moderation_stage === ModerationStages.PASSED
                    ? `Gig: <b>${this.service.name}</b> was successfully published`
                    : 'Your Gig was successfully submitted for moderation. We will notify you of the outcome shortly.',
                  failureText: (e) => {
                    const defaultErr = 'Gig publishing error'
                    return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
                  }
                })
                const params: GAEvent = {
                  event: 'gig-published'
                }
                if (this.isEdit) {
                  params.gig_id = this.service.id
                }
                googleAnalyticsV2.send(params)
              } catch (e) {
                this.parseError(e)
              } finally {
                this.closeSnackbar(snackbarId)
              }
            } else {
              const snackbarId = uniqueId()
              setTimeout(async () => {
                this.openSnackbar({
                  id: snackbarId,
                  type: this.SnackTypes.SUCCESS,
                  text: `Gig: <b>${this.service.name}</b> was saved as a draft`
                })
              }, 500)
            }
            this.$router.push({ name: SERVICE_DETAILS, params: { id, slug: parseSlug(slug) } })
          }
        } else {
          this.validateFail()
          const analyticParams: GAEvent = {
            event: 'gig-button-publish-failed',
            name: this.errors.items.map((item: any) => item.field).join(','),
          }
          if (this.$route.query.createGigFrom === 'gig') {
            analyticParams['event-content'] = 'add-gig-from-another-gig'
          } else if (this.$route.query.createGigFrom === 'profile') {
            analyticParams['event-content'] = 'add-gig-from-profile'
          }
          if (this.isEdit) {
            analyticParams.gig_id = this.service.id
          }
          googleAnalyticsV2.send(analyticParams)
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.processing = false
        this.processingSaved = false
        this.processingSavedAndPublish = false
      }
    },
    validateFail () {
      if (this.errors.has('title')) {
        this.$root.$emit('set-focus', 'name-input')
      } else if (this.errors.has('category') || this.errors.has('skills')) {
        animateScrollTo(this.$refs.skillSection)
      } else if (this.errors.has('description')) {
        this.$root.$emit('set-focus', 'description-input')
      } else if (this.errors.has('price')) {
        animateScrollTo(this.$refs.rateSection)
        const rateInput = this.$refs.rateInput.$el
        rateInput.querySelector('input').focus()
      } else if (this.errors.has('cover')) {
        animateScrollTo(this.$refs.bannerSection)
      } else if (this.errors.has('currency')) {
        animateScrollTo(this.$refs.currencySection)
      }
    },
    async onClickSignup () {
      if (await this.validateAll()) {
        this.openModal({
          component: 'lx-sign-up-modal',
          props: {
            predefinedRole: Roles.FREELANCER,
            meta: { gig: this.getServerFormatGig() },
            onSuccess: () => {
              this.readOnly = true
            },
          }
        })
      } else {
        this.validateFail()
      }
    },
    toServerCurrencies () {
      return this.preferredCurrencies.map((pc: Currency) => ({
        currency: pc.backendId,
        blockchain: pc.blockchain,
      }))
    },
    getServerFormatGig () {
      if (this.isEdit) {
        const service = Gig.toServer(this.service)
        service.relations.Currency = this.toServerCurrencies()
        return service
      }
      return {
        ...this.service,
        relations: {
          ...this.service.relations,
          Currency: this.toServerCurrencies(),
        }
      }
    },
    onTitleFocus () {
      const params: GAEvent = {
        event: 'field-start',
        name: 'gig-title',
      }
      if (this.isEdit) {
        params.gig_id = this.service.id
      }
      googleAnalyticsV2.send(params)
    },
    onTitleFocusout () {
      const params: GAEvent = {
        event: 'field-finished',
        name: 'gig-title',
      }
      if (this.isEdit) {
        params.gig_id = this.service.id
      }
      googleAnalyticsV2.send(params)
    },
    onDescriptionFocus () {
      const params: GAEvent = {
        event: 'field-start',
        name: 'description',
      }
      if (this.isEdit) {
        params.gig_id = this.service.id
      }
      googleAnalyticsV2.send(params)
    },
    onDescriptionFocusout () {
      const params: GAEvent = {
        event: 'field-finished',
        name: 'description',
      }
      if (this.isEdit) {
        params.gig_id = this.service.id
      }
      googleAnalyticsV2.send(params)
    },
    onManageSkillClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "skills-modals" */ '@/modals/GigSkillsSelection/GigSkillsSelection.vue'),
          title: 'Skills',
          props: {
            category: this.category,
            skills: this.service.relations.Skill,
            skillsLimit: 10,
            onSuccess: (skills: Skill[]) => {
              this.service.relations.Skill = skills
            }
          }
        }
      })
    },
    onManageCurrenciesClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "skills-modals" */ '@/modals/CurrencySelection/CurrencySelection.vue'),
          title: 'Preferred payment options',
          props: {
            currencies: this.preferredCurrencies,
            forProfile: false,
            onSuccess: (currencies: Currency[]) => {
              this.preferredCurrencies = currencies
            }
          }
        }
      })
    }
  },
  metaInfo () {
    return {
      title: this.isEdit ? 'Edit Gig' : 'Create gig and get paid crypto',
      meta: [
        {
          name: 'description',
          // eslint-disable-next-line max-len
          content: `Create your own gigs on LaborX and get paid in cryptocurrency.`,
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
      ]
    }
  },
})
