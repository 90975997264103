import Vue from 'vue'
import bookmarkable from './bookmarkable'

export default Vue.extend<any, any, any, any>({
  mixins: [bookmarkable],
  props: {
    btnClass: {
      type: String,
      default: () => 'lx-outline-blue-btn',
    },
  },
})
