import Vue from 'vue'
import { SERVICE_BY_SKILL } from '@/constants/routes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import GigCard from '@/partials/GigCard/GigCard.vue'

export default Vue.extend<any, any, any, any>({
  components: { GigCard },
  props: {
    gigs: Array,
    skill: String,
    skillDetails: Object,
  },
  data () {
    return {
      observer: null,
      SERVICE_BY_SKILL,
      sendedGigs: [],
    }
  },
  mounted () {
    this.initObserver()
  },
  updated () {
    this.observer.disconnect()
    this.sendedGigs = []
    this.$nextTick(() => this.initObserver())
  },
  beforeDestroy () {
    if (process.client) {
      this.destroyObserver()
    }
  },
  methods: {
    initObserver () {
      if (!this.observer) {
        const options = {
          rootMargin: '0px',
          threshold: 0.90
        }
        this.observer = new IntersectionObserver(this.onVisible, options)
      }
      if (this.$refs.gig) {
        const gigs = Array.isArray(this.$refs.gig) ? this.$refs.gig : [this.$refs.gig]
        gigs.forEach((gig: any, i: number) => {
          if (!this.sendedGigs.includes(this.gigs[i].id)) {
            this.observer.observe(gig.$el || gig)
          }
        })
      }
    },
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
    onVisible (entries: any) {
      const gigsNotification = []
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9) {
          const index = this.$refs.gig.findIndex((gig: any) => entry.target === (gig.$el || gig))
          if (this.gigs[index] && !this.sendedGigs.includes(this.gigs[index].id)) {
            this.sendedGigs.push(this.gigs[index].id)
            gigsNotification.push({ gig: this.gigs[index], index })
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (gigsNotification.length > 0) {
        this.notificateAboutGigs(gigsNotification)
      }
    },
    notificateAboutGigs (gigs: any) {
      const event = {
        event: 'impressions',
        ecommerce: {
          currencyCode: 'USD',
          impressions: gigs.map((gig: any) => ({
            name: gig.gig.name,
            id: `${gig.gig.id}`,
            price: gig.gig.rate,
            category: this.getCategory(gig.gig),
            brand: 'Gigs',
            list: this.$route.fullPath,
            position: (gig.index + 1),
          })),
        }
      }
      googleAnalyticsV2.send(event)
    },
    getCategory (gig: any) {
      if (this.skillDetails?.name) {
        return this.skillDetails?.name
      }
      if (!gig.skills || gig.skills.length === 0) {
        return ''
      }
      return gig.skills[0].name
    },
    onGigClick (gig: any, index: number) {
      googleAnalyticsV2.send({
        event: 'productClick',
        ecommerce: {
          currencyCode: 'USD',
          click: {
            products: [{
              name: gig.name,
              id: `${gig.id}`,
              price: gig.rate,
              category: this.getCategory(gig),
              brand: 'Gigs',
              list: this.$route.fullPath,
              position: (index + 1),
            }]
          }
        },
      })
    }
  }
})
