import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Review from '@/models-ts/user/Review'
import meanBy from 'lodash/meanBy'
import ReviewCardNew from '@/partials/ReviewCardNew/ReviewCard.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    ReviewCardNew,
  },
  data () {
    return {
      reviewsLimit: 2,
    }
  },
  computed: {
    ...mapGetters({
      gigDetails: 'browseGigs/gigDetails',
    }),
    freelancer () {
      return this.gigDetails.relations.Freelancer
    },
    reviews () {
      return [...this.freelancer.meta.reviews || []].sort((a, b) => {
        if (a.gig_job_id === this.gigDetails.id) {
          return -1
        }
        return b.id - a.id
      }).map(Review.fromServer)
    },
    avgReviews () {
      const sum = this.reviews.reduce((summary: number, current: Review) => {
        summary += Number((meanBy(current.ratings, 'rate') || 0).toFixed(1))
        return summary
      }, 0)
      return this.reviews.length ? (sum / this.reviews.length).toFixed(1) : 0
    },
    viewedReviews () {
      return this.reviews.slice(0, this.reviewsLimit)
    },
  },
  watch: {
    '$route.params.id': {
      handler (newGig, oldGig) {
        if (this.$route.params.id && String(newGig) !== String(oldGig)) {
          this.reviewsLimit = 2
        }
      }
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onOpenReviewPopup () {
      googleAnalyticsV2.send({
        event: 'gig-click-on-review-creator',
        'event-content': 'open-popup',
        gig_id: this.gigDetails.id,
      })
    },
    onClickReviewLink () {
      googleAnalyticsV2.send({
        event: 'gig-click-on-review-creator',
        'event-content': 'link-click',
        gig_id: this.gigDetails.id,
      })
    },
    onClickMoreReviews () {
      this.reviewsLimit += 2
      googleAnalyticsV2.send({
        event: 'gig-click-on-load_more_reviews',
        gig_id: this.gigDetails.id,
      })
    },
  },
})
