import cloneDeep from 'lodash/cloneDeep'
import Image from '@/models/Image'
import AccountType from '@/constants/user/AccountType'
import Avatar from '@/models/user/Avatar'
import Skill from '@/models-ts/Skill'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'

export default class GigShort {
  id: number | null
  slug: string | null
  name: string | null
  banner: Image | null
  skills: Array<Skill> | null
  rate: string | null
  timeType: GigTimeTypes | null
  timeValue: number | null
  userId: number | null
  userType: AccountType | null
  userName: string | null
  avatar: Avatar | null
  avgReview: number | null
  userReviews: number | null
  views: number | null

  constructor (data: Partial<GigShort>) {
    Object.assign(this, cloneDeep({
      ...data,
      avgReview: (data.avgReview || 0).toFixed(2),
    }))
  }
}
