import { mapState } from 'vuex'

export default {
  data () {
    return {
      hashtags: [
        'CryptoFreelance',
        'CryptoJobs',
        'CryptoJob',
        'BlockchainJob',
      ]
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user?.id,
    }),
    shareUrl () {
      const ref = this.userId || this.$route.query.ref
      return `${process.env.VUE_APP_FRONTEND_URL + this.$route.path}${ref ? `?ref=${ref}` : ''}`
    },
  },
  methods: {
    getShareImage (pageTitle = '', randId = 0) {
      let letter = pageTitle.charAt(0).toLowerCase()
      const LETTER_COLORS = ['blue', 'purple', 'yellow']
      const color = LETTER_COLORS[randId % LETTER_COLORS.length]

      if (!/^[a-z]*$/.test(letter)) {
        letter = 'default'
      }

      return `${process.env.VUE_APP_FRONTEND_URL}/static/images/letter/${letter}-${color}.png`
    }
  },
}
