/* eslint-disable max-len */
export default [
  {
    question: `How to hire a good freelancer?`,
    answer: `Start by checking out a freelancer’s portfolio and any links they provide to work they have done previously. You should then do some due diligence. You can check how suitable a freelancer is for the role you have in mind using LaborX’s decentralised reputation system. This combines blockchain-based data about completed jobs with feedback from previous customers, enabling you to see at a glance how competent and reliable a service provider is. Always use the secure, integrated chat to communicate and ask any questions you have for them.`
  },
  {
    question: `What jobs pay best in crypto?`,
    answer: `The highest-paying jobs in crypto are technical roles, including blockchain engineers, architects, and smart contract developers. However, due to the knowledge and experience the top jobs require (whether they’re technical or non-technical), and the shortage of qualified candidates, rates of pay can be significantly higher than average no matter what the job. After engineering roles, marketing is one of the best-paid areas in the Web3 sector.`
  },
  {
    question: 'What skills do blockchain jobs require?',
    answer: 'Technical roles in the blockchain sector will require one or more popular programming languages (C++, Python, Node.js, JavaScript, Rust, and others, depending on the specific job). While in-depth knowledge of blockchain is not always required, some background in computer security, cryptography and P2P networking may be helpful. The most important quality is a capacity (and willingness) to learn about new technologies and protocols.'
  }
]
