import Vue from 'vue'
import striptags from 'striptags'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import shareableMixin from '@/mixins/shareableMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import { SERVICE_BY_SKILL, SERVICES, SERVICE_DETAILS_BY_ID, SERVICE_DETAILS } from '@/constants/routes'
import { parseTemplate } from '@/utils/parser'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import ActionsCard from './ActionsCard/ActionsCard.vue'
import Review from '@/models-ts/user/Review'
import meanBy from 'lodash/meanBy'
import GigCard from '@/partials/GigCard/GigCard.vue'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { RootState } from '@/store'
import rolebleMixin from '@/mixins/rolebleMixin'
import GigStatus from './GigStatus/GigStatus.vue'
import GigDescription from './GigDescription/GigDescription.vue'
import UserInfoSection from './UserInfoSection/UserInfoSection.vue'
import MoreGigsSection from './MoreGigsSection/MoreGigsSection.vue'
import MobileActionsCard from './MobileActionsCard/MobileActionsCard.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, shareableMixin, canonicalMixin, rolebleMixin],
  components: {
    GigCard,
    ActionsCard,
    GigStatus,
    GigDescription,
    UserInfoSection,
    UserInfo,
    MoreGigsSection,
    MobileActionsCard,
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      isLoaded: (state: RootState) => state.browseGigs.details?.isLoaded,
      isLoading: (state: RootState) => state.browseGigs.details?.isLoading,
      prefetched: (state: RootState) => state.browseGigs.prefetched,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      gigDetails: 'browseGigs/gigDetails',
    }),
    isOwner () {
      return this.isLoggedIn && this.gigDetails?.user_id === this.userId
    },
    breadcrumbs () {
      return [
        { title: 'Gigs', to: { name: SERVICES } },
        this.category?.name ? {
          title: this.category.name,
          to: { name: SERVICE_BY_SKILL, params: { skill: this.category.url } },
        } : null,
        { title: this.gigDetails ? this.gigDetails.name : 'Gig' }
      ].filter(Boolean)
    },
    serviceId () {
      return this.$route.name === SERVICE_DETAILS && this.$route.params.id
    },
    name () {
      return this.gigDetails.name
    },
    category () {
      return this.gigDetails.meta.category || {}
    },
    freelancer () {
      return this.gigDetails.relations.Freelancer
    },
    reviews () {
      return [...this.freelancer.meta.reviews || []].sort((a, b) => {
        if (a.gig_job_id === this.gigDetails.id) {
          return -1
        }
        return b.id - a.id
      }).map(Review.fromServer)
    },
    avgReviews () {
      const sum = this.reviews.reduce((summary: number, current: Review) => {
        summary += Number((meanBy(current.ratings, 'rate') || 0).toFixed(1))
        return summary
      }, 0)
      return this.reviews.length ? (sum / this.reviews.length).toFixed(1) : 0
    },
    hasStatus () {
      return this.isOwner && this.isFreelancer
    },
    metaTitleOG () {
      if (!this.gigDetails) {
        return 'Loading'
      }
      return `${this.freelancer.name}: ${this.gigDetails.name}`
    },
    metaTitle () {
      if (!this.gigDetails) {
        return 'Loading'
      }
      return `${this.gigDetails.name} - ${this.freelancer.name} - freelance jobs gig`
    },
  },
  watch: {
    '$route.params.id': {
      handler (newGig, oldGig) {
        if (this.$route.params.id && String(newGig) !== String(oldGig)) {
          this.clearDetails()
          this.loadGig()
        }
      }
    },
    isLoaded: {
      handler () {
        if (this.isLoaded) {
          this.$nextTick(async () => {
            this.sendAnalytics()
          })
        }
      },
      immediate: true,
    }
  },
  async prefetch () {
    try {
      if (process.server) {
        if (!this.isLoggedIn) {
          await this.load({ id: this.$route.params.id, slug: this.$route.params.slug })
          this.setPrefetched(true)
        } else {
          this.$store.commit('responseHeaders/disableCacheControl')
        }
      }
      if (this.$route.name === SERVICE_DETAILS_BY_ID && this.gigDetails) {
        this.setRedirect(parseTemplate(SERVICE_DETAILS, { id: this.gigDetails.id, slug: this.gigDetails.slug }))
      }
    } catch (e) {
      if (ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
        this.setNotFound(true)
      } else {
        console.error('Fetch gig details error:', e)
      }
    }
  },
  created () {
    this.loadGig()
  },
  beforeDestroy () {
    if (!this.serviceId) {
      this.clearDetails()
    }
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'browseGigs/setPrefetched',
      clearDetails: 'browseGigs/clearDetails',
    }),
    ...mapActions({
      load: 'browseGigs/load',
    }),
    async loadGig () {
      try {
        if (process.client) {
          if (!this.prefetched) {
            if (String(this.gigDetails?.id) !== String(this.serviceId)) {
              await this.load({ id: this.$route.params.id, slug: this.$route.params.slug })
            }
            if (this.$route.name === SERVICE_DETAILS_BY_ID) {
              this.$router.replace({ name: SERVICE_DETAILS, params: { id: this.gigDetails.id, slug: this.gigDetails.slug } })
            }
          } else {
            this.setPrefetched(false)
          }
        }
      } catch (e) {
        if (ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
          this.setNotFound(true)
        } else {
          this.parseError(e)
        }
      }
    },
    sendAnalytics () {
      googleAnalyticsV2.send({
        event: 'gig-view',
        gig_id: this.gigDetails.id,
      })
      googleAnalyticsV2.send({
        event: 'detailView',
        gig_id: this.gigDetails.id,
        ecommerce: {
          detail: {
            actionField: {
              list: this.$route.query.fromUrl || '',
            },
            products: [{
              name: this.gigDetails.name,
              id: `${this.gigDetails.id}`,
              price: this.gigDetails.rate,
              category: this.$route.query.category || this.category?.name || '',
              brand: 'Gigs',
            }]
          }
        }
      })
    }
  },
  metaInfo () {
    const meta = []
    const script = []
    if (this.gigDetails) {
      const description = striptags(this.gigDetails.description).slice(0, 258)
      meta.push({
        vmid: 'description',
        name: 'description',
        content: `${description}. Find top online services with LaborX ✔️`,
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: this.metaTitleOG,
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: this.gigDetails.banner?.src,
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: this.shareUrl,
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      })
      if (!this.gigDetails.isSeoVisible) {
        meta.push({
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        })
      }
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Product',
          name: this.gigDetails.name,
          image: [this.gigDetails.banner?.src],
          description,
          ...(this.reviews.length && {
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: this.avgReviews,
              reviewCount: this.reviews.length,
            }
          }),
          offers: {
            '@type': 'Offer',
            price: this.gigDetails.rate,
            priceCurrency: 'USD',
          },
        }
      })
    }
    return {
      title: this.metaTitle,
      meta,
      script,
      link: [this.canonicalLink],
    }
  }
})
