import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'
import actionsCardMixin from '../actionsCardMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin],
  components: { NoSsr, BookmarkIconButton },
  computed: {
    hasBtns () {
      return this.hasHireBtn || this.hasPostBtn || this.hasBookmarkBtn
    },
  },
})
