import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { SERVICE_BY_SKILL, SERVICES } from '@/constants/routes'
import { withCommas } from '@/utils/moneyFormat'
import GigPalette from '@/partials/GigPalette/GigPalette.vue'
import GigLoader from '@/partials/GigLoader/GigLoader.vue'
import SeoQuestions from '@/partials/SeoQuestions/SeoQuestions.vue'
import responseMixin from '@/mixins/responseMixin'
import seoSkillMixin from '@/mixins/seoSkillMixin'
import shareableMixin from '@/mixins/shareableMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'
import FilterCard from './FilterCrad/FilterCard.vue'
import { MAX_BUDGET, MIN_BUDGET, PAYMENT_OPTIONS, RATING_OPTIONS, SORT_OPTIONS } from './FilterCrad/FilterCard'
import skillMixins from './mixins/skillMixins'
import questions from './questions'
import { RootState } from '@/store'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { formatDate } from '@/utils/date'
import { DATE_MONTH_YEAR_FORMAT } from '@/constants/utils/date'
import Skill from '@/models-ts/Skill'
import { SeoSkillEntityTypes } from '@/constants/backend/SeoSkill'

const GIGS_LIMIT = 30

const POPULAR_SKILLS = [
  'development',
  'blockchain-and-crypto',
  'design-and-creative',
  'nft',
  'writing',
  'sales-and-marketing',
]
const AVG_SKILLS = [
  'blockchain-and-crypto',
  'design-and-creative',
  'sales-and-marketing',
  'writing',
  'development',
  'nft',
  'gaming',
  'audio-and-video',
]

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, shareableMixin, seoSkillMixin, skillMixins, canonicalMixin],
  components: {
    InfiniteLoading,
    NoSsr,
    GigLoader,
    GigPalette,
    FilterCard,
    SeoQuestions,
  },
  data () {
    return {
      SERVICE_BY_SKILL,
      questions,
      totalLoading: false,
      processing: false,
      infiniteId: 0,
      filtersCount: 0,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      prefetched: (state: RootState) => state.browseGigs.prefetched,
      servicesPreloading: (state: RootState) => state.browseGigs.gigsBySkill.isLoading,
      isLoaded: (state: RootState) => state.browseGigs.gigsBySkill.isLoaded,
      pagination: (state: RootState) => state.browseGigs.gigsBySkill.pagination,
    }),
    ...mapGetters({
      gigsBySkill: 'browseGigs/gigsBySkill',
    }),
    total () {
      return this.pagination.total || 0
    },
    totalFoundText () {
      return `${this.total} Gig${this.total === 1 ? '' : 's'} found`
    },
    byCategory () {
      return this.$route.name === SERVICE_BY_SKILL
    },
    pageTitle () {
      return this.byCategory && this.skillDetails?.name
        ? `${this.skillDetails.name} Gigs`
        : 'Browse Gigs'
    },
    popularSkills () {
      return POPULAR_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: SERVICE_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    avgSkills () {
      return AVG_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .sort((a: any, b: any) => b.level === a.level ? b.id - a.id : b.level - a.level)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: SERVICE_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    breadcrumbs () {
      return [
        { title: 'Gigs', to: { name: SERVICES } },
        { title: this.skillDetails.name, to: { name: SERVICES, params: { skill: this.skillDetails.url } } }
      ]
    },
    hasAvgReviews () {
      return this.skillDetails.freelancers_score_based_on > 0
    },
    metaTitle () {
      if (this.skillsLoading) {
        return 'Loading'
      }
      const monthDate = formatDate(new Date(), DATE_MONTH_YEAR_FORMAT)
      const gigsCount = this.skillDetails.meta?.previous_month_count?.gigs
      const prefix = gigsCount
        ? `${this.skillDetails.meta?.previous_month_count?.gigs}+ `
        : ''
      const postfix = gigsCount
        ? ` in ${monthDate}`
        : ''
      return this.getSeoTitle(
        this.skillDetails,
        SeoSkillEntityTypes.GIG,
        `${prefix}${this.skillDetails.name} Services Online${postfix}`,
      )
    },
    metaDescription () {
      if (this.skillsLoading) {
        return null
      }
      return this.getSeoDescription(
        this.skillDetails,
        SeoSkillEntityTypes.GIG,
        // eslint-disable-next-line max-len
        `Buy the best ${this.skillDetails.name} services from freelancers on LaborX. Lowest market commission. Find top ${this.skillDetails.name} Services with LaborX ✔️`,
      )
    },
    seoDescription () {
      if (this.skillsLoading) {
        return null
      }
      if (this.skillDetails?.name) {
        return this.getSeoText(
          this.skill,
          SeoSkillEntityTypes.GIG,
          `We found ${this.total} ${this.skillDetails.name} Gig${this.total !== 1 ? 's' : ''} that paid in cryptocurrency.
Search for work, find a remote gig that suits your needs, and apply now!`
        )
      }
      return `We found ${this.total} Blockchain and Crypto Gig${this.total !== 1 ? 's' : ''} that paid in cryptocurrency.
Search for work, find a remote gig that suits your needs, and apply now!`
    },
    pageHead () {
      if (this.skillsLoading) {
        return null
      }
      return this.getSeoPageHead(
        this.skillDetails,
        SeoSkillEntityTypes.GIG,
        this.skillDetails.name
      )
    },
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.reloadServices()
        lxAnalytics.send('browse-gigs', this.getFilterFromQuery())
      }
    },
  },
  async prefetch () {
    if (process.server) {
      const skillUrl = this.$route.params.skill
      let predefinedSkills = []
      try {
        predefinedSkills = await this.getSkills()
      } catch (e) {
        console.error('Error fetching services:', e)
      }
      if (skillUrl) {
        if (!predefinedSkills.find((skill: Skill) => skill.url === skillUrl)) {
          this.setNotFound(true)
          this.setPrefetched(true)
          return
        }
        if (this.skillDetails.change_id) {
          const newSkill = predefinedSkills.find((skill: Skill) => skill.id === this.skillDetails.change_id)
          if (newSkill) {
            this.setRedirect(this.$route.fullPath.replace(this.skillDetails.url, newSkill.url))
            return
          }
        }
        if (this.skillDetails.is_removed) {
          this.setRedirect('/gigs')
          return
        }
      }
      await this.loadServices(this.getFilterFromQuery())
      this.setPrefetched(true)
      this.$store.commit('responseHeaders/disableCacheControl')
    }
  },
  async created () {
    if (process.client) {
      try {
        await this.getSkills()
        if (!this.prefetched) {
          this.setPagination({ limit: GIGS_LIMIT, offset: 0 })
          await this.loadServices(this.getFilterFromQuery())
        } else {
          this.getFilterFromQuery()
        }
        this.infiniteId = +new Date()
        this.setPrefetched(false)
        lxAnalytics.send('browse-gigs', this.getFilterFromQuery())
      } catch (e) {
        this.parseError(e)
      }
    }
  },
  mounted () {
    googleAnalyticsV2.send({
      event: 'browse-gigs',
    })
  },
  methods: {
    withCommas,
    ...mapActions({
      openModal: 'ui/openModal',
      getSkills: 'skills/getSkills',
      loadServices: 'browseGigs/loadGigsBySkill',
      loadMoreServices: 'browseGigs/loadMoreGigsBySkill',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'browseGigs/setPrefetched',
      setPagination: 'browseGigs/setPagination',
    }),
    async reloadServices () {
      this.setPagination({ limit: GIGS_LIMIT, offset: 0 })
      await this.loadServices(this.getFilterFromQuery())
      this.$nextTick(() => this.infiniteId++)
    },
    async onLoadMore ($state: any) {
      try {
        if (this.gigsBySkill.length < this.pagination.total) {
          this.totalLoading = true
          this.setPagination({ limit: GIGS_LIMIT, offset: this.gigsBySkill.length })
          await this.loadMoreServices(this.getFilterFromQuery())
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    getFilterFromQuery () {
      const {
        search,
        sort = null,
        payment,
        rating,
        budgetFrom,
        skill = [],
        budgetTo
      } = this.$route.query
      const sortValue = SORT_OPTIONS.find((opt: any) => opt.value === sort)
      const paymentValue = PAYMENT_OPTIONS.find((opt: any) => opt.value === +payment)
      const ratingValue = RATING_OPTIONS.find((opt: any) => opt.value === +rating)
      const bFrom = (Number.isNaN(+budgetFrom) || +budgetFrom === MIN_BUDGET) ? null : +budgetFrom
      const bTo = (Number.isNaN(+budgetTo) || +budgetTo === MAX_BUDGET) ? null : +budgetTo
      let skillsValue = []
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          skillsValue.push(mainSkill.id)
        }
      }
      let skillsArray = Array.isArray(skill) ? skill : [skill]
      skillsValue = skillsValue.concat(skillsArray
        .map(id => this.predefinedSkills.find((opt: any) => +opt.id === +id)?.id)
        .filter(Boolean)
      )
      skillsValue = [...new Set(skillsValue)]
      this.filtersCount = 0 + (search ? 1 : 0) +
        (sortValue ? 1 : 0) +
        (paymentValue ? 1 : 0) +
        (ratingValue ? 1 : 0) +
        (bFrom ? 1 : 0) +
        (bTo ? 1 : 0) +
        (skillsValue.length > 0 ? 1 : 0)
      return {
        search: search?.trim(),
        skills: skillsValue,
        sort: sortValue?.queryValue,
        direction: sortValue?.direction || null,
        payment: paymentValue?.value,
        rating: ratingValue?.value,
        budgetFrom: bFrom,
        budgetTo: bTo,
      }
    },
    onClickOpenFilters () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "gig-modals" */ './FilterModal/FilterModal.vue'),
          title: 'Filters',
          props: {
          }
        }
      })
    },
    onMereSkillClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ '@/modals/NavigationBySkills/NavigationBySkills.vue'),
          title: 'Categories',
        }
      })
    },
  },

  metaInfo () {
    if (this.$route.name === SERVICES) {
      const meta = [{
        name: 'description',
        vmid: 'description',
        // eslint-disable-next-line max-len
        content: 'Get the best services from freelancers on LaborX. Lowest market commission. Buy top online blockchain services for cryptocurrency with LaborX ✔️',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Browse Gigs',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: 'Find a freelancer for your needs on blockchain-based crypto freelance platform LaborX',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/landing-preview.png`,
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: this.shareUrl,
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      }]
      return {
        title: 'Online freelance services platform',
        meta,
      }
    } else {
      const meta = []
      if (this.metaDescription) {
        meta.push({
          vmid: 'description',
          name: 'description',
          content: this.metaDescription,
        })
      }
      if (this.skillDetails?.name) {
        meta.push({
          vmid: 'og:title',
          property: 'og:title',
          content: `${this.skillDetails.name} - Hire a freelancer on LaborX and pay in crypto per task`
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.metaDescription,
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/laborx-thumbnail.jpg`
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: this.shareUrl,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        })
      }
      return {
        title: this.metaTitle,
        meta,
        link: [this.canonicalLink]
      }
    }
  },
})
