import { sendApplication } from '@/api/gig'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import { SERVICE_ADD } from '@/constants/routes'
import { Roles } from '@/constants/user/roles'
import rolebleMixin from '@/mixins/rolebleMixin'
import snackMixin from '@/mixins/snackMixin'
import { GigApplicationFormData } from '@/modals/SendApplication/SendApplication'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { RootState } from '@/store'
import ErrorMatcher from '@/utils/ErrorMatcher'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  data () {
    return {
      bookmarkType: BookmarkTypes.GIG,
    }
  },
  computed: {
    ...mapState<RootState>({
      customerIsNewbie: (state: RootState) => state.user?.customerIsNewbie,
      profiles: (state: any) => state.user?.profiles,
      isLoggedIn: (state: RootState) => state.app.authorized,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      gigDetails: 'browseGigs/gigDetails',
    }),
    isOwner () {
      return this.isLoggedIn && this.gigDetails?.user_id === this.userId
    },
    canHire () {
      return !this.isOwner && (!this.isLoggedIn || this.isCustomer)
    },
    hasHireBtn () {
      return this.canHire
    },
    hasPostBtn () {
      return !this.isLoggedIn || (!this.isOwner && this.isFreelancer)
    },
    hasBookmarkBtn () {
      return !this.isLoggedIn || (!this.isOwner && this.isCustomer)
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      switchRole: 'user/switchRole',
    }),
    ...mapMutations({
      setCustomerIsNewbie: 'user/setCustomerIsNewbie',
    }),
    async onClickHire () {
      googleAnalyticsV2.send({
        event: 'buy-this-gig-button',
        gig_id: this.gigDetails.id,
      })
      if (this.isLoggedIn) {
        if (this.isCustomer) {
          this.openOfferModal()
        } else if (this.profiles[Roles.CUSTOMER]) {
          await this.switchRole(Roles.CUSTOMER)
          googleAnalyticsV2.send({
            event: 'change-role',
            'event-content': 'customer',
            gig_id: this.gigDetails.id,
          })
          this.openOfferModal()
        } else {
          this.openModal({
            component: 'lx-lazy-modal',
            props: {
              factory: import(/* webpackChunkName: "add-role-modal" */ '@/modals/auth/AddRole/AddRole.vue'),
              title: 'Customer Registration',
              props: {
                predefinedRole: Roles.CUSTOMER,
                successCb: () => this.openOfferModal(),
              }
            }
          })
        }
      } else {
        this.openOfferModal()
      }
    },
    async onClickPost () {
      const route = {
        name: SERVICE_ADD,
        query: {
          createGigFrom: 'gig'
        }
      }
      if (!this.isLoggedIn || this.isFreelancer) {
        this.$router.push(route)
      } else if (this.profiles[Roles.FREELANCER]) {
        await this.switchRole(Roles.FREELANCER)
        googleAnalyticsV2.send({
          event: 'change-role',
          'event-content': 'talent',
          gig_id: this.gigDetails.id,
        })
        this.$router.push(route)
      } else {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "add-role-modal" */ '@/modals/auth/AddRole/AddRole.vue'),
            title: 'Freelancer Registration',
            props: {
              predefinedRole: Roles.FREELANCER,
              successCb: () => {
                this.$router.push(route)
              },
            }
          }
        })
      }
      googleAnalyticsV2.send({
        event: 'click-on-button-post-gig',
        gig_id: this.gigDetails.id,
      })
    },
    openOfferModal () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendApplication/SendApplication.vue'),
          title: 'Buy this Gig',
          props: {
            budget: this.gigDetails.rate,
            onSuccess: (application: GigApplicationFormData) => this.isLoggedIn
              ? this.sendGigApplication(application)
              : this.openModal({
                component: 'lx-sign-up-modal',
                props: {
                  predefinedRole: Roles.CUSTOMER,
                  meta: {
                    gig_application: {
                      gig_id: this.gigDetails.id,
                      ...application,
                    }
                  }
                }
              })
          }
        }
      })
    },
    async sendGigApplication (application: GigApplicationFormData) {
      try {
        const { moderation_stage } = await sendApplication({
          gig_id: this.gigDetails.id,
          ...application,
        })
        googleAnalyticsV2.send({
          event: 'buy-this-gig-form-send',
          gig_id: this.gigDetails.id,
        })
        googleAnalyticsV2.send({
          event: 'addToCart',
          gig_id: this.gigDetails.id,
          ecommerce: {
            currencyCode: 'USD',
            add: {
              products: [{
                name: this.gigDetails.name,
                id: `${this.gigDetails.id}`,
                price: application.budget,
                category: '',
                quantity: 1,
                brand: 'Gigs',
              }]
            }
          }
        })
        this.openSnackbar({
          type: this.SnackTypes.SUCCESS,
          text: moderation_stage === ModerationStages.MANUAL
            ? 'Your Gig application was successfully submitted for moderation. We will notify you of the outcome shortly.'
            : 'Your application has been submitted.'
        })
      } catch (err) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: ErrorMatcher.isConflict(err)
            ? 'You have already enquired about this Gig.'
            : 'Error submitting application. Please try again.',
        })
      }
      if (this.customerIsNewbie) {
        this.setCustomerIsNewbie(false)
      }
    },
  }
})
